"use strict";
// External Files
const bottomBarTemplate = require('../templates/bottomBar.html');
// Interfaces
const bottomBar = {
    name: 'bottomBar',
    template: bottomBarTemplate,
    props: [],
    methods: {},
};
module.exports = bottomBar;
