"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IView = void 0;
var IView;
(function (IView) {
    IView["home"] = "home";
    IView["created"] = "created";
    IView["about"] = "about";
    IView["dashboard"] = "dashboard";
    IView["status"] = "status";
    IView["sdk"] = "sdk";
})(IView = exports.IView || (exports.IView = {}));
