"use strict";
// Templates
const statsTemplate = require('../templates/stats.html');
const stats = {
    name: 'stats',
    template: statsTemplate,
    data() {
        return {};
    },
    methods: {},
    mounted() {
    },
};
module.exports = stats;
