"use strict";
// Templates
const quoteTemplate = require('../templates/quote.html');
const quote = {
    name: 'quote',
    template: quoteTemplate,
    data() {
        return {};
    },
    methods: {},
    mounted() {
    },
};
module.exports = quote;
