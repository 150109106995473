"use strict";
// Templates
const featuresTemplate = require('../templates/features.html');
const features = {
    name: 'features',
    template: featuresTemplate,
    data() {
        return {};
    },
    methods: {},
    mounted() {
    },
};
module.exports = features;
